import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { StateContext } from '../context/context'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { user } = useContext(StateContext)

  if (!user && location.pathname !== '/app/login') {
    navigate('/app/login')
    return null
  }
  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
}

export default PrivateRoute
