import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
// import SEO from "./seo"
import { CognitoUser } from 'amazon-cognito-identity-js'
import { UserPool } from '../context/context'
import seal from '../static/images/kdc-seal.png'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const [showForm, setShowForm] = useState(true)
  const [codeSent, setCodeSent] = useState(false)
  const [showPasswordError, setShowPasswordError] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [showPasswordFormatError, setShowPasswordFormatError] = useState(false)
  const [formData, setFormData] = useState({
    confirmCode: '',
    email: '',
    password: '',
    passwordConfirm: '',
  })

  const { email, confirmCode, password, passwordConfirm } = formData

  const hasValidPassword =
    password &&
    password.length &&
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
      password
    )

  const hasValidEmail =
    email &&
    email.length &&
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

  const isDisabled =
    !password ||
    !passwordConfirm ||
    !confirmCode ||
    confirmCode.length !== 6 ||
    password !== passwordConfirm ||
    !hasValidPassword

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  useEffect(() => {
    if (password !== passwordConfirm) setShowPasswordError(true)
    if (password === passwordConfirm) setShowPasswordError(false)

    if (email.length && !hasValidEmail) setShowEmailError(true)
    if (hasValidEmail) setShowEmailError(false)

    if (password.length && !hasValidPassword) setShowPasswordFormatError(true)
    if (hasValidPassword) setShowPasswordFormatError(false)
  }, [onChange])

  const getUser = () => {
    return new CognitoUser({
      Username: email,
      Pool: UserPool,
    })
  }

  const onSendCode = e => {
    e.preventDefault()

    getUser().forgotPassword({
      onSuccess: data => {
        // console.log("on success", data)
      },
      onFailure: err => {
        // console.error("on failure", err)
      },
      inputVerificationCode: data => {
        // console.log("input verification code:", data)
        setCodeSent(true)
      },
    })
  }

  const onSubmit = async e => {
    e.preventDefault()

    if (password !== passwordConfirm) {
      setShowPasswordError(true)
    }

    getUser().confirmPassword(confirmCode, password, {
      onSuccess: data => {
        setShowForm(false)
        // console.log("on success", data)
      },
      onFailure: err => {
        console.error('on failure', err)
      },
    })
  }

  return (
    <div className="forgot-password">
      {/* <SEO title="Register" /> */}
      <h1>{t('FORGOT_PASSWORD')}</h1>
      <img className="seal" src={seal} />
      {showForm && (
        <form className="form-container">
          <label htmlFor="email">{t('EMAIL')}</label>
          {showEmailError && (
            <h6 className="red centered">{t('EMAIL_ERROR')}</h6>
          )}
          <input type="email" name="email" onChange={e => onChange(e)} />
          <button
            disabled={!hasValidEmail}
            className={`btn-primary ${!hasValidEmail && 'disabled'}`}
            onClick={onSendCode}
          >
            {t('SEND_CODE')}
          </button>
          {codeSent && <p>{t('CONFIRMATION_CODE_SUCCESS')}</p>}
          <label htmlFor="confirmCode">{t('CONFIRM_CODE')}</label>
          <input type="text" name="confirmCode" onChange={e => onChange(e)} />
          <label htmlFor="password">{t('PASSWORD')}</label>
          <h6 className="info-text indent-1">{t('PASSWORD_REQUIREMENTS')}</h6>
          {showPasswordFormatError && (
            <h6 className="red centered">{t('PASSWORD_ERROR')}</h6>
          )}
          <input type="password" name="password" onChange={e => onChange(e)} />
          <label htmlFor="passwordConfirm">{t('PASSWORD_CONFIRM')}</label>
          <input
            type="password"
            name="passwordConfirm"
            onChange={e => onChange(e)}
          />
          {showPasswordError && (
            <h6 className="red centered">{t('PASSWORD_NO_MATCH')}</h6>
          )}
          <button
            disabled={isDisabled}
            className={`btn-primary ${isDisabled && 'disabled'}`}
            onClick={onSubmit}
          >
            {t('CHANGE_PASSWORD')}
          </button>
        </form>
      )}
      {!showForm && (
        <>
          <h4 className="brown centered m-2 mt-2">
            {t('CHANGE_PASSWORD_SUCCESS')}
          </h4>
          <h4 className="brown centered m-2">{t('CHANGE_PASSWORD_INFO')}</h4>
        </>
      )}
      <Link className="btn-secondary link-button" to="/app/login">
        {t('LOGIN')}
      </Link>
    </div>
  )
}

export default ForgotPassword
