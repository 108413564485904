import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import i18n from '../i18n/initI18n'
import { useTranslation } from 'react-i18next'

// const filesQuery = graphql`
// query {
//   allContentfulDashboard {
//     group(field: node_locale) {
//       nodes {
//         node_locale
//         loanInfoDocs {
//           title
//           description
//           file {
//             url
//             fileName
//           }
//         }
//       }
//     }
//   }
// }
// `

const AcceptTermsCheckbox = ({ acceptTerms, setAcceptTerms }) => {
  const { t } = useTranslation()
  // const localeMap = {
  //   fi: 'fi-FI',
  //   en: 'en-US'
  // }
  // const files = useStaticQuery(filesQuery)
  // const fileGroups = files.allContentfulDashboard.group
  // const fileArray = fileGroups.map(fg => {
  //   const {loanInfoDocs, node_locale} = fg.nodes[0]
  //   return { [node_locale]: loanInfoDocs }
  // })

  // const filteredFiles = fileArray.find(file => file[localeMap[i18n.language]])

  return (
    <div className="checkbox-container flex-row">
      <input
        type="checkbox"
        name="checkbox"
        onChange={() => setAcceptTerms(!acceptTerms)}
      />
      <label htmlFor="checkbox">
        <h4>{t('ACCEPT_TERMS_DOCUMENTS')}</h4>
      </label>
    </div>
  )
}

AcceptTermsCheckbox.propTypes = {
  acceptTerms: PropTypes.bool.isRequired,
  setAcceptTerms: PropTypes.func.isRequired,
}

export default AcceptTermsCheckbox
