import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StateContext } from '../context/context'
import Spinner from './Spinner'
import { API_PATH } from '../utils/constants'
import PaymentInformation from './PaymentInformation'

const TabUserLoans = () => {
  const { user } = useContext(StateContext)
  const { t } = useTranslation()
  // const [waitlistStatus, setWaitlistStatus] = useState(null)
  const [reserved, setReserved] = useState(null)
  const [reservedContracts, setReservedContracts] = useState(null)
  const [signedContracts, setSignedContracts] = useState(null)
  const [signed, setSigned] = useState(null)
  const loanValue = 5000

  const updateLoanStatuses = async () => {
    let data = await fetch(
      `${API_PATH}/caskloans-app/sheets-update-loan-statuses`,
      {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${user.token}`,
        },
        method: 'POST',
        body: JSON.stringify({
          email: user.email,
          name: user.name,
        }),
      }
    )

    data = await data.json()
    return data
    // console.log("UPDATE STATUSES", data)
  }

  const fetchUserData = async () => {
    //Update loan statuses as the first step to get recently signed contracts
    await updateLoanStatuses()

    // Waitlist
    // let waitlist = await fetch(`${API_PATH}/caskloans-app/sheets-get-waitlist`, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${user.token}`,
    //     email: user.email,
    //   },
    // })
    // waitlist = await waitlist.json()
    // console.log("WAITLIST", waitlist)
    // setWaitlistStatus(waitlist.isOnWaitlist)

    let data = await fetch(`${API_PATH}/caskloans-app/sheets-user-loans`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    })

    data = await data.json()
    // setAvailable({
    //   totalLoansCount: data.totalLoansCount,
    //   availableLoansCount: data.availableLoansCount,
    //   // availableLoansCount: 0,
    //   // availableLoanIds: data. availableLoanIds
    // })
    setReserved([...data.userReservedLoans])
    setSigned([...data.userSignedLoans])
    setReservedContracts([...data.userReservedContracts])
    setSignedContracts([...data.userSignedContracts])
  }

  useEffect(() => {
    // updateLoanStatuses()
    fetchUserData()
  }, [])

  // const handleCancel = async e => {
  //   e.preventDefault();
  //   let data = await fetch(`${API_PATH}/caskloans-app/sheets-cancel-loans`, {
  //     // headers: {
  //     //   'Content-Type': 'application/json',
  //     //   Authorization: `Bearer ${CUSTOBAR_TOKEN}`,
  //     // },
  //     method: 'POST',
  //     body: JSON.stringify({
  //       cancelLoanIds: reserved,
  //       email: user.email,
  //       name: user.name
  //     }),
  //   })

  //     data = await data.json()

  //     console.log("cancel", data)
  //     setReserved([])
  //     setAvailable({...available, availableLoansCount: data.newAvailableLoansCount})
  // }

  return (
    <div className="tab-user-loans">
      {/* <SEO title="Dashboard" /> */}

      <h2>{t('MY_SIGNED_LOANS')}</h2>
      {signedContracts ? (
        signedContracts.length ? (
          signedContracts.map(contract => (
            <div className="loan-info-container indent-1" key={contract.id}>
              <h4>{t('LOAN_ID')}</h4>
              <p>{contract.id}</p>
              <h4>{t('LOAN_VALUE')}</h4>
              <p>
                {(contract.barrels.length * parseInt(loanValue)).toLocaleString(
                  'FI'
                )}
                €
              </p>
              <h4>{t('BARREL_IDS')}:</h4>
              {contract.barrels.map(barrel => (
                <p key={barrel}>{barrel}</p>
              ))}
            </div>
          ))
        ) : (
          <p className="indent-1 mt-1">{t('NO_SIGNED_LOANS')}</p>
        )
      ) : (
        <div className="indent-1">
          <Spinner />
        </div>
      )}

      <h2 className="mt-2">{t('MY_RESERVED_LOANS')}</h2>
      {reservedContracts ? (
        reservedContracts.length ? (
          <>
            {reservedContracts.map(contract => (
              <div className="loan-info-container indent-1" key={contract.id}>
                <h4>{t('LOAN_ID')}</h4>
                <p>{contract.id}</p>
                <h4>{t('LOAN_VALUE')}</h4>
                <p>
                  {(
                    contract.barrels.length * parseInt(loanValue)
                  ).toLocaleString('FI')}
                  €
                </p>
                <h4>{t('BARREL_IDS')}:</h4>
                {contract.barrels.map(barrel => (
                  <p key={barrel}>{barrel}</p>
                ))}
              </div>
            ))}
            <h4 className="brown indent-1">{t('RESERVED_LOANS_REMINDER')}</h4>
            <PaymentInformation />
          </>
        ) : (
          <p className="indent-1 mt-1">{t('NO_RESERVED_LOANS')}</p>
        )
      ) : (
        <div className="indent-1">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default TabUserLoans
