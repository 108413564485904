import React, { useState, useEffect, useContext } from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import i18n from '../i18n/initI18n'
import IBAN from 'iban'
import { useTranslation } from 'react-i18next'
import { StateContext } from '../context/context'
// import SEO from "./seo"
import AcceptTermsCheckbox from './AcceptTermsCheckbox'
import AcceptTermsFiles from './AcceptTermsFiles'
import Spinner from './Spinner'
import PaymentInformation from './PaymentInformation'
import { API_PATH } from '../utils/constants'
import createCustomer from '../utils/klaviyo'

const TabAvailableLoans = () => {
  const { user } = useContext(StateContext)
  const { t } = useTranslation()
  const [tab, setTab] = useState(1)
  const [conditions, setConditions] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [checked, setChecked] = useState('user')
  const [reserveLoading, setReserveLoading] = useState(false)
  const [showReserveSuccess, setShowReserveSuccess] = useState(false)
  // const [waitlistStatus, setWaitlistStatus] = useState(null)
  const [available, setAvailable] = useState(null)
  const [formData, setFormData] = useState({
    loanValue: '5000',
    loanCount: '0',
    bankAccount: '',
    dateOfBirth: user.dateOfBirth,
    companyId: '',
    companyName: '',
    personalIdNumber: '',
  })
  const [userReservedLoanValue, setUserReservedLoanValue] = useState('')
  const [maillistStatus, setMaillistStatus] = useState(false)
  const [maillistData, setMaillistData] = useState({
    email: '',
  })
  const [showBankAccountError, setShowBankAccountError] = useState(false)
  const [showPersonalIdNumberError, setShowPersonalIdNumberError] = useState(
    false
  )
  const [showCompanyIdError, setShowCompanyIdError] = useState(false)

  const updateLoanStatuses = async () => {
    let data = await fetch(
      `${API_PATH}/caskloans-app/sheets-update-loan-statuses`,
      {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${user.token}`,
        },
        method: 'POST',
        body: JSON.stringify({
          email: user.email,
          name: user.name,
        }),
      }
    )

    data = await data.json()
    return data
    // console.log("UPDATE STATUSES", data)
  }

  const fetchUserData = async () => {
    //Update loan statuses as the first step to get recently signed contracts
    await updateLoanStatuses()

    // Waitlist
    // let waitlist = await fetch(`${API_PATH}/caskloans-app/sheets-get-waitlist`, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${user.token}`,
    //     email: user.email,
    //   },
    // })
    // waitlist = await waitlist.json()
    // console.log("WAITLIST", waitlist)
    // setWaitlistStatus(waitlist.isOnWaitlist)

    let data = await fetch(`${API_PATH}/caskloans-app/sheets-user-loans`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    })

    data = await data.json()
    setAvailable({
      totalLoansCount: data.totalLoansCount,
      availableLoansCount: data.availableLoansCount,
      // availableLoansCount: 0,
      // availableLoanIds: data. availableLoanIds
    })
  }

  useEffect(() => {
    // updateLoanStatuses()
    fetchUserData()
  }, [])

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const onLoanCountChange = e => {
    // Prevent more than available loans count being set as the value
    const value =
      e.target.value > available.availableLoansCount
        ? available.availableLoansCount
        : e.target.value

    setFormData({
      ...formData,
      [e.target.name]: value,
    })
  }
  const onMaillistData = e =>
    setMaillistData({ ...maillistData, [e.target.name]: e.target.value })

  const onCheck = e => {
    setFormData({ ...formData, companyId: '', companyName: '' })
    setChecked(e.target.value)
  }

  const handleReserve = async e => {
    e.preventDefault()
    setReserveLoading(true)
    let data = await fetch(`${API_PATH}/caskloans-app/sheets-reserve-loans`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      method: 'POST',
      body: JSON.stringify({
        // email: user.email,
        name: checked === 'company' ? formData.companyName : user.name,
        reservedLoanCount: formData.loanCount,
        bankAccount: formData.bankAccount.toUpperCase(),
        dateOfBirth: formData.dateOfBirth,
        personalIdNumber: formData.personalIdNumber.toUpperCase(),
        companyId: formData.companyId,
        companyName: formData.companyName,
      }),
    })

    data = await data.json()
    // console.log("reserv", data)
    setUserReservedLoanValue(
      (
        parseInt(formData.loanValue) * parseInt(formData.loanCount) || 0
      ).toLocaleString('FI')
    )
    setAvailable({
      ...available,
      availableLoansCount: data.newAvailableLoansCount,
    })
    setFormData({ ...formData, loanCount: '0', bankAccount: '' })
    setReserveLoading(false)
    setShowReserveSuccess(true)
  }

  const onSubmitMaillist = async e => {
    e.preventDefault()
    let data = await createCustomer({
      email: maillistData.email,
      can_email: true,
      mailing_lists: ['loan-waiters'],
    })
    setMaillistStatus(true)
  }

  const hasValidBankAccount = IBAN.isValid(formData.bankAccount)
  const hasValidPersonalIdNumber =
    formData.personalIdNumber.length === 11 &&
    (formData.personalIdNumber[6] === '-' ||
      formData.personalIdNumber[6] === '+' ||
      formData.personalIdNumber[6].toUpperCase() === 'A')
  const hasValidCompanyId =
    formData.companyId.length === 9 &&
    /^[0-9]{7}-[0-9]{1}/.test(formData.companyId)

  const isDisabled =
    formData.loanCount < 1 ||
    !conditions ||
    !formData.bankAccount ||
    !hasValidBankAccount ||
    !formData.dateOfBirth ||
    !formData.personalIdNumber ||
    !hasValidPersonalIdNumber ||
    (checked === 'company' && !formData.companyId) ||
    (checked === 'company' && !hasValidCompanyId) ||
    (checked === 'company' && !formData.companyName)

  useEffect(() => {
    if (formData.bankAccount.length && !hasValidBankAccount)
      setShowBankAccountError(true)
    if (hasValidBankAccount) setShowBankAccountError(false)

    if (formData.personalIdNumber.length && !hasValidPersonalIdNumber)
      setShowPersonalIdNumberError(true)
    if (hasValidPersonalIdNumber) setShowPersonalIdNumberError(false)

    if (formData.companyId.length && !hasValidCompanyId)
      setShowCompanyIdError(true)
    if (hasValidCompanyId) setShowCompanyIdError(false)
  }, [onChange])

  // const handleWaitlist = async e => {
  //   e.preventDefault()

  //   let data = await fetch(`${API_PATH}/caskloans-app/sheets-add-waitlist`, {
  //     // headers: {
  //     //   'Content-Type': 'application/json',
  //     //   Authorization: `Bearer ${CUSTOBAR_TOKEN}`,
  //     // },
  //     method: 'POST',
  //     body: JSON.stringify({
  //       email: user.email,
  //       name: user.name
  //     }),
  //   })

  //   data = await data.json()
  //   setWaitlistStatus(data.isOnWaitlist)
  // }

  return (
    <>
      {/* <SEO title="Dashboard" /> */}

      {/* <h2>{t('ALL_LOAN_INFO')}</h2> */}

      <div className="available-loans-container">
        <p>{t('AVAILABLE_LOANS_INFO_TEXT')}</p>
        <div className="p-1">
          <h4>{t('TOTAL_LOANS')}:</h4>
          {available && available.totalLoansCount ? (
            <h4 className="indent-1">{available.totalLoansCount}</h4>
          ) : (
            <div className="indent-1">
              <Spinner />
            </div>
          )}
        </div>
        <div>
          <h4>{t('AVAILABLE_LOANS')}:</h4>
          {available &&
          available.availableLoansCount !== (null || undefined) ? (
            <>
              <h4 className="indent-1">{available.availableLoansCount}</h4>
              {parseInt(available.availableLoansCount) > 0 ? (
                <div className="mt-1">
                  <AcceptTermsFiles />
                  <AcceptTermsCheckbox
                    acceptTerms={acceptTerms}
                    setAcceptTerms={setAcceptTerms}
                  />
                </div>
              ) : (
                <div className="mt-1">
                  <p>{t('NO_AVAILABLE_LOANS_INFO')}</p>
                  {maillistStatus === false && (
                    <form className="form-container">
                      <label htmlFor="email">{t('EMAIL')}</label>
                      <input
                        type="email"
                        name="email"
                        onChange={e => onMaillistData(e)}
                      />
                      <button
                        className="btn-primary"
                        onClick={onSubmitMaillist}
                      >
                        {t('WAITLIST_BUTTON')}
                      </button>
                    </form>
                  )}
                  {maillistStatus === true && (
                    <>
                      <div className="m-2" />
                      <h4 className="brown">{t('WAITLIST_STATUS')}</h4>
                      <div className="m-2" />
                    </>
                  )}
                  <div className="mt-1">
                    <AcceptTermsFiles />
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="indent-1">
              <Spinner />
            </div>
          )}
        </div>
      </div>

      {acceptTerms && <hr className="hr" />}
      {acceptTerms && (
        <div>
          <h2>{t('RESERVE_LOANS')}</h2>
          {/* <p>{t('LOAN_VALUE')}: {formData.loanValue}</p> */}
          <form className="form-container">
            <div className="flex-row loan-value-container">
              <div className="flex-column">
                <label htmlFor="loanCount">{t('LOAN_COUNT')}</label>
                <input
                  type="number"
                  min="0"
                  max={available.availableLoansCount}
                  name="loanCount"
                  value={formData.loanCount}
                  onChange={e => onLoanCountChange(e)}
                />
              </div>
              <div className="flex-column loan-value">
                <h4>{t('LOAN_VALUE')}:</h4>
                <h4>
                  {(
                    parseInt(formData.loanValue) *
                      parseInt(formData.loanCount) || 0
                  ).toLocaleString('FI')}
                  €
                </h4>
              </div>
            </div>

            <h4>{t('LENDER_ENTITY_TITLE')}</h4>
            <div className="flex-row">
              <input
                type="radio"
                value="user"
                name="radio-user"
                checked={checked === 'user'}
                onChange={e => onCheck(e)}
              />
              <label htmlFor="radio-user">{t('IS_USER_LABEL')}</label>
            </div>
            <div className="flex-row">
              <input
                type="radio"
                value="company"
                name="radio-company"
                checked={checked === 'company'}
                onChange={e => onCheck(e)}
              />
              <label htmlFor="radio-company">{t('IS_COMPANY_LABEL')}</label>
            </div>

            <label htmlFor="bankAccount">{t('BANK_ACCOUNT')}</label>
            {showBankAccountError && (
              <h6 className="red centered">{t('BANK_ACCOUNT_ERROR')}</h6>
            )}
            <input
              type="text"
              name="bankAccount"
              // maxLength={18}
              value={formData.bankAccount.toUpperCase()}
              onChange={e => onChange(e)}
            />

            <label htmlFor="personalIdNumber">{t('PERSONAL_ID_NUMBER')}</label>
            {showPersonalIdNumberError && (
              <h6 className="red centered">{t('PERSONAL_ID_NUMBER_ERROR')}</h6>
            )}
            <input
              type="text"
              name="personalIdNumber"
              maxLength={11}
              value={formData.personalIdNumber.toUpperCase()}
              onChange={e => onChange(e)}
            />

            {checked === 'user' && (
              <>
                <label htmlFor="dateOfBirth">{t('DATE_OF_BIRTH')}</label>
                <input
                  disabled={true}
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={e => onChange(e)}
                />
              </>
            )}

            {checked === 'company' && (
              <>
                <label htmlFor="companyId">{t('COMPANY_ID')}</label>
                {showCompanyIdError && (
                  <h6 className="red centered">{t('COMPANY_ID_ERROR')}</h6>
                )}
                <input
                  disabled={!checked}
                  type="text"
                  name="companyId"
                  maxLength={9}
                  value={formData.companyId}
                  onChange={e => onChange(e)}
                />
                <label htmlFor="companyName">{t('COMPANY_NAME')}</label>
                <input
                  disabled={!checked}
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={e => onChange(e)}
                />
              </>
            )}

            <div className="checkbox-container flex-row">
              <input
                type="checkbox"
                name="checkbox"
                onChange={() => setConditions(!conditions)}
              />
              <label htmlFor="checkbox">
                <div>
                  {t('ACCEPT_LABEL')}{' '}
                  <Link to={`/sopimusehdot-ja-tietosuoja`}>
                    {t('DASHBOARD_CONDITIONS_LINK')}
                  </Link>
                </div>
              </label>
            </div>

            <div className="flex-column black-banner">
              <h4>{t('RESERVE_LOANS_CONFIRM_TEXT')}</h4>
              <div className="m-1" />
              <h4>
                {(
                  parseInt(formData.loanValue) * parseInt(formData.loanCount) ||
                  0
                ).toLocaleString('FI')}
                €
              </h4>
            </div>

            {available && parseInt(available.availableLoansCount) > 0 && (
              <>
                <button
                  className={`btn-primary ${
                    (isDisabled || reserveLoading) && 'disabled'
                  }`}
                  disabled={isDisabled || reserveLoading}
                  onClick={handleReserve}
                >
                  {t('RESERVE_LOANS_BUTTON')}
                </button>
              </>
            )}
            {/* {available && parseInt(available.availableLoansCount) === 0 && waitlistStatus !== null &&
          (waitlistStatus 
            ? <p>{t('WAITLIST_STATUS')}</p>
            : <button onClick={handleWaitlist}>
                {t('WAITLIST_BUTTON')}
              </button>
          )
        } */}
          </form>
          {reserveLoading && (
            <>
              <div className="reserve-loans-spinner-container">
                <Spinner />
              </div>
              <h5 className="brown centered-text">
                {t('RESERVE_LOADING_INFO_TEXT')}
              </h5>
            </>
          )}
          {showReserveSuccess && (
            <>
              <h5 className="brown centered-text mt-2">
                {t('RESERVE_SUCCESS_INFO_TEXT')}
              </h5>
              <PaymentInformation userLoanValue={userReservedLoanValue} />
            </>
          )}
        </div>
      )}
    </>
  )
}

export default TabAvailableLoans
