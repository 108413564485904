import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TabAvailableLoans from './TabAvailableLoans'
import TabUserLoans from './TabUserLoans'

const Tabs = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(0)

  const tabMap = [
    {
      label: t('TAB_AVAILABLELOANS_LABEL'),
      component: <TabAvailableLoans />,
    },
    {
      label: t('TAB_USERLOANS_LABEL'),
      component: <TabUserLoans />,
    },
  ]

  const tabContent = index => {
    return tabMap[index].component
  }

  return (
    <>
      <div className="tab-button-container">
        {tabMap.map((tab, i) => (
          <button
            className="button button-underline"
            key={i}
            onClick={() => setTab(i)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <hr />
      <div className="tab-content-container">{tabContent(tab)}</div>
    </>
  )
}

export default Tabs
