import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import i18n from '../i18n/initI18n'
import { useTranslation } from 'react-i18next'

const filesQuery = graphql`
  query {
    allContentfulDashboard {
      group(field: node_locale) {
        nodes {
          node_locale
          loanInfoDocs {
            title
            description
            file {
              url
              fileName
            }
          }
        }
      }
    }
  }
`

const AcceptTermsFiles = () => {
  const { t } = useTranslation()
  const localeMap = {
    fi: 'fi-FI',
    en: 'en-US',
  }
  const files = useStaticQuery(filesQuery)
  const fileGroups = files.allContentfulDashboard.group
  const fileArray = fileGroups.map(fg => {
    const { loanInfoDocs, node_locale } = fg.nodes[0]
    return { [node_locale]: loanInfoDocs }
  })

  const filteredFiles = fileArray.find(file => file[localeMap[i18n.language]])

  return (
    <div className="accept-terms">
      <h4>{t('USER_ACCEPT_TERMS_TITLE')}</h4>
      {filteredFiles &&
        filteredFiles[localeMap[i18n.language]].map(file => {
          return (
            <a
              className="indent-1"
              key={file.title}
              href={`https:${file.file.url}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {file.title}
            </a>
          )
        })}
    </div>
  )
}

export default AcceptTermsFiles
