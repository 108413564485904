import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const PaymentInformation = ({ userLoanValue }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  return (
    <div className="info-text m-2 mt-2">
      <button
        className="button button-underline payment-information-button"
        onClick={() => setOpen(!open)}
      >
        {t('PAYMENT_INFORMATION_TITLE')}
        <span>{open ? '▼' : '▶'}</span>
      </button>
      {open && (
        <p className="bottom-border-brown p-1 indent-1">
          {t('PAYMENT_INFORMATION', {
            loanValue: userLoanValue.length ? userLoanValue + '€' : '',
          })}
        </p>
      )}
    </div>
  )
}

PaymentInformation.propTypes = {
  userLoanValue: PropTypes.string,
}

PaymentInformation.defaultProps = {
  userLoanValue: '',
}

export default PaymentInformation
