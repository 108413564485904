import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
// import {StateContext} from '../context/context'
// import SEO from "./seo"
import Tabs from './Tabs'
import logo from '../static/images/kdc-logo.png'
import seal from '../static/images/kdc-seal-white.png'
// import { API_PATH } from '../utils/constants'

const Dashboard = () => {
  const { t } = useTranslation()
  // const {user} = useContext(StateContext)
  // const [acceptTerms, setAcceptTerms] = useState(false)
  // const [checked, setChecked] = useState(false)
  // // const [waitlistStatus, setWaitlistStatus] = useState(null)
  // const [available, setAvailable] = useState(null)
  // const [reserved, setReserved] = useState(null)
  // const [reservedContracts, setReservedContracts] = useState(null)
  // const [signedContracts, setSignedContracts] = useState(null)
  // const [signed, setSigned] = useState(null)
  // const [formData, setFormData] = useState({
  //   loanValue: '5000',
  //   loanCount: '0',
  //   bankAccount: '',
  //   dateOfBirth: user.dateOfBirth,
  //   companyId: '',
  //   companyName: '',
  // })

  // const updateLoanStatuses = async () => {

  //   let data = await fetch(`${API_PATH}/caskloans-app/sheets-update-loan-statuses`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       // Authorization: `Bearer ${user.token}`,
  //     },
  //     method: 'POST',
  //     body: JSON.stringify({
  //       email: user.email,
  //       name: user.name
  //     }),
  //   })

  //   data = await data.json()
  //   return data
  //   // console.log("UPDATE STATUSES", data)
  // }

  // const fetchUserData = async () => {

  //   //Update loan statuses as the first step to get recently signed contracts
  //   await updateLoanStatuses()

  //   // Waitlist
  //   // let waitlist = await fetch(`${API_PATH}/caskloans-app/sheets-get-waitlist`, {
  //   //   headers: {
  //   //     'Content-Type': 'application/json',
  //   //     Authorization: `Bearer ${user.token}`,
  //   //     email: user.email,
  //   //   },
  //   // })
  //   // waitlist = await waitlist.json()
  //   // console.log("WAITLIST", waitlist)
  //   // setWaitlistStatus(waitlist.isOnWaitlist)

  //   let data = await fetch(`${API_PATH}/caskloans-app/sheets-user-loans`, {
  //     headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //   })

  //   data = await data.json()
  //   setAvailable({
  //     totalLoansCount: data.totalLoansCount,
  //     availableLoansCount: data.availableLoansCount,
  //     // availableLoansCount: 0,
  //     // availableLoanIds: data. availableLoanIds
  //   })
  //   setReserved([...data.userReservedLoans])
  //   setSigned([...data.userSignedLoans])
  //   setReservedContracts([...data.userReservedContracts])
  //   setSignedContracts([...data.userSignedContracts])
  // }

  // useEffect(() => {
  //   // updateLoanStatuses()
  //   fetchUserData()
  // }, [])

  // const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  // const onCheck = e => {
  //   if(checked) setFormData({...formData, companyId: '', companyName: ''})
  //   setChecked(!checked)
  // }

  // const handleReserve = async e => {
  //   e.preventDefault();
  //   let data = await fetch(`${API_PATH}/caskloans-app/sheets-reserve-loans`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${user.token}`,
  //     },
  //     method: 'POST',
  //     body: JSON.stringify({
  //       // email: user.email,
  //       name: checked ? formData.companyName : user.name,
  //       reservedLoanCount: formData.loanCount,
  //       bankAccount: formData.bankAccount,
  //       dateOfBirth: formData.dateOfBirth,
  //       companyId: formData.companyId,
  //       companyName: formData.companyName
  //     }),
  //   })

  //   data = await data.json()
  //   // console.log("reserv", data)
  //   setReserved([...reserved, ...data.reservedLoans])
  //   setReservedContracts([...reservedContracts, data.reservedContract])
  //   setAvailable({...available, availableLoansCount: data.newAvailableLoansCount})
  // }

  // const handleCancel = async e => {
  //   e.preventDefault();
  //   let data = await fetch(`${API_PATH}/caskloans-app/sheets-cancel-loans`, {
  //     // headers: {
  //     //   'Content-Type': 'application/json',
  //     //   Authorization: `Bearer ${CUSTOBAR_TOKEN}`,
  //     // },
  //     method: 'POST',
  //     body: JSON.stringify({
  //       cancelLoanIds: reserved,
  //       email: user.email,
  //       name: user.name
  //     }),
  //   })

  //     data = await data.json()

  //     console.log("cancel", data)
  //     setReserved([])
  //     setAvailable({...available, availableLoansCount: data.newAvailableLoansCount})
  // }

  // const handleSign = async e => {
  //   e.preventDefault();

  //   let data = await fetch(`${API_PATH}/caskloans-app/sheets-sign-loans`, {
  //     // headers: {
  //     //   'Content-Type': 'application/json',
  //     //   Authorization: `Bearer ${CUSTOBAR_TOKEN}`,
  //     // },
  //     method: 'POST',
  //     body: JSON.stringify({
  //       signLoanIds: reserved,
  //       email: user.email,
  //       name: user.name
  //     }),
  //   })

  //     data = await data.json()

  //     console.log("sign", data)
  //     setReserved([])
  //     setSigned([...signed, ...data.signedLoans])
  // }

  // const handleWaitlist = async e => {
  //   e.preventDefault()

  //   let data = await fetch(`${API_PATH}/caskloans-app/sheets-add-waitlist`, {
  //     // headers: {
  //     //   'Content-Type': 'application/json',
  //     //   Authorization: `Bearer ${CUSTOBAR_TOKEN}`,
  //     // },
  //     method: 'POST',
  //     body: JSON.stringify({
  //       email: user.email,
  //       name: user.name
  //     }),
  //   })

  //   data = await data.json()
  //   setWaitlistStatus(data.isOnWaitlist)
  // }

  return (
    <>
      <div className="dashboard-hero-container">
        <img className="dashboard-logo" src={logo}></img>
        <h1 className="dashboard-title">{t('DASHBOARD_TITLE')}</h1>
        <img className="dashboard-seal" src={seal}></img>
      </div>
      <Tabs />
    </>
  )
}

export default Dashboard
