import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import i18n from '../i18n/initI18n'
import { useTranslation } from 'react-i18next'
// import SEO from "./seo"
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { UserPool } from '../context/context'
import seal from '../static/images/kdc-seal.png'
import createCustomer from '../utils/klaviyo'

const Register = () => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    familyName: '',
    email: '',
    dateOfBirth: '',
    password: '',
    passwordConfirm: '',
  })
  const [showForm, setShowForm] = useState(true)
  const [showPasswordError, setShowPasswordError] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [showDateOfBirthError, setShowDateOfBirthError] = useState(false)
  const [showPasswordFormatError, setShowPasswordFormatError] = useState(false)

  const {
    firstName,
    familyName,
    email,
    dateOfBirth,
    password,
    passwordConfirm,
  } = formData

  const hasValidEmail =
    email &&
    email.length &&
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

  const hasValidPassword =
    password &&
    password.length &&
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~=`])\S{8,99}$/.test(
      password
    )

  const checkDateOfBirth = dateOfBirth => {
    const formattedDateOfBirth = dateOfBirth.replace(/-/g, '/')
    const userDateOfBirth = new Date(formattedDateOfBirth)
    const currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00'
    const age = ~~((Date.now(currentDate) - userDateOfBirth) / 31557600000)
    if (age < 18) {
      return false
    } else {
      return true
    }
  }

  const hasValidDateOfBirth =
    dateOfBirth && dateOfBirth.length && checkDateOfBirth(dateOfBirth)

  const isDisabled =
    !checked ||
    !dateOfBirth ||
    !hasValidDateOfBirth ||
    !password ||
    !passwordConfirm ||
    !hasValidPassword ||
    !email ||
    !hasValidEmail ||
    !firstName ||
    !familyName ||
    password !== passwordConfirm

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  useEffect(() => {
    if (password !== passwordConfirm) setShowPasswordError(true)
    if (password === passwordConfirm) setShowPasswordError(false)

    if (email.length && !hasValidEmail) setShowEmailError(true)
    if (hasValidEmail) setShowEmailError(false)

    if (dateOfBirth.length && !hasValidDateOfBirth)
      setShowDateOfBirthError(true)
    if (hasValidDateOfBirth) setShowDateOfBirthError(false)

    if (password.length && !hasValidPassword) setShowPasswordFormatError(true)
    if (hasValidPassword) setShowPasswordFormatError(false)
  }, [onChange])

  const onSubmit = async e => {
    e.preventDefault()
    const birthdateFormatted = `${dateOfBirth.split('-')[1]}/${
      dateOfBirth.split('-')[2]
    }/${dateOfBirth.split('-')[0]}`

    const attributeList = []

    const dataFirstName = {
      Name: 'name',
      Value: firstName,
    }

    const dataFamilyName = {
      Name: 'family_name',
      Value: familyName,
    }

    const dataBirthdate = {
      Name: 'birthdate',
      Value: birthdateFormatted,
    }
    const attributeFirstName = new CognitoUserAttribute(dataFirstName)
    const attributeFamilyName = new CognitoUserAttribute(dataFamilyName)
    const attributeBirthdate = new CognitoUserAttribute(dataBirthdate)

    attributeList.push(attributeFirstName)
    attributeList.push(attributeFamilyName)
    attributeList.push(attributeBirthdate)

    if (password !== passwordConfirm) {
      setShowPasswordError(true)
      return
    }

    UserPool.signUp(email, password, attributeList, null, async (err, data) => {
      if (err) {
        console.error(err)
      } else {
        createCustomer({ email, mailing_lists: ['caskloans-register'] })
      }

      setShowForm(false)
    })
  }

  return (
    <div className="register">
      {/* <SEO title="Register" /> */}
      <h1>{t('REGISTER')}</h1>
      <h3>{t('REGISTER_INFO')}</h3>
      <img className="seal" src={seal} />
      {showForm && (
        <form className="form-container">
          <label htmlFor="firstName">{t('FIRST_NAME')}</label>
          <input type="text" name="firstName" onChange={e => onChange(e)} />
          <label htmlFor="familyName">{t('FAMILY_NAME')}</label>
          <input type="text" name="familyName" onChange={e => onChange(e)} />
          <label htmlFor="email">{t('EMAIL')}</label>
          {showEmailError && (
            <h6 className="red centered">{t('EMAIL_ERROR')}</h6>
          )}
          <input type="email" name="email" onChange={e => onChange(e)} />
          <label htmlFor="dateOfBirth">{t('DATE_OF_BIRTH')}</label>
          {showDateOfBirthError && (
            <h6 className="red centered">{t('DATE_OF_BIRTH_ERROR')}</h6>
          )}
          <input type="date" name="dateOfBirth" onChange={e => onChange(e)} />
          <label htmlFor="password">{t('PASSWORD')}</label>
          <h6 className="info-text indent-1">{t('PASSWORD_REQUIREMENTS')}</h6>
          {showPasswordFormatError && (
            <h6 className="red centered">{t('PASSWORD_ERROR')}</h6>
          )}
          <input type="password" name="password" onChange={e => onChange(e)} />
          <label htmlFor="passwordConfirm">{t('PASSWORD_CONFIRM')}</label>
          <input
            type="password"
            name="passwordConfirm"
            onChange={e => onChange(e)}
          />
          {showPasswordError && (
            <h6 className="red centered">{t('PASSWORD_NO_MATCH')}</h6>
          )}
          <div className="checkbox-container flex-row">
            <input
              type="checkbox"
              name="checkbox"
              onChange={() => setChecked(!checked)}
            />
            <label htmlFor="checkbox">
              <div>
                {t('USER_ACCEPT_TERMS_LABEL')}{' '}
                <Link to={`/sopimusehdot-ja-tietosuoja`}>
                  {t('DASHBOARD_CONDITIONS_LINK')}
                </Link>
              </div>
            </label>
          </div>
          <button
            disabled={isDisabled}
            className={`btn-primary ${isDisabled && 'disabled'}`}
            onClick={onSubmit}
          >
            {t('REGISTER')}
          </button>
        </form>
      )}
      {!showForm && (
        <>
          <h4 className="brown centered m-2 mt-2">{t('REGISTER_SUCCESS')}</h4>
          <h4 className="brown centered m-2">
            {t('REGISTER_CHECK_EMAIL_INFO')}
          </h4>
        </>
      )}
      <h5>{t('LOGIN_LABEL')}</h5>
      <Link className="btn-secondary link-button" to="/app/login">
        {t('LOGIN')}
      </Link>
    </div>
  )
}

export default Register
