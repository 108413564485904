import React, { useState, useContext } from 'react'
import { Link, navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
// import SEO from "./seo"
import { DispatchContext } from '../context/context'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import { UserPool } from '../context/context'
import seal from '../static/images/kdc-seal.png'

const Login = () => {
  const dispatch = useContext(DispatchContext)
  const { t } = useTranslation()
  const [showLoginError, setShowLoginError] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = async e => {
    e.preventDefault()
    setShowLoginError(false)

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    })

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })

    user.authenticateUser(authDetails, {
      onSuccess: data => {
        const { payload } = data.getIdToken()
        const token = data.getIdToken().getJwtToken()
        // const {payload, jwtToken} = data.getAccessToken()

        const user = {
          email: payload.email,
          name: `${payload.name} ${payload.family_name}`,
          dateOfBirth: `${payload.birthdate.split('/')[2]}-${
            payload.birthdate.split('/')[0]
          }-${payload.birthdate.split('/')[1]}`,
          token: token,
        }

        dispatch({ type: 'LOGIN', payload: user })
        navigate('/app/dashboard')
      },
      onFailure: err => setShowLoginError(true),
      // newPasswordRequired: data => console.log("newPasswordRequired", data)
    })
  }

  return (
    <div className="login">
      {/* <SEO title="Register" /> */}
      <h1>{t('LOGIN_TITLE')}</h1>
      <h3>{t('LOGIN_INFO')}</h3>
      <img className="seal" src={seal} />
      <form className="form-container">
        <label htmlFor="email">{t('EMAIL')}</label>
        <input type="email" name="email" onChange={e => onChange(e)} />
        <label htmlFor="password">{t('PASSWORD')}</label>
        <input type="password" name="password" onChange={e => onChange(e)} />
        <button className="btn-primary" onClick={onSubmit}>
          {t('LOGIN')}
        </button>
      </form>
      {showLoginError && (
        <h6 className="red centered m-2">{t('INCORRECT_LOGIN')}</h6>
      )}
      <Link className="link-primary" to="/app/forgot-password">
        {t('FORGOT_PASSWORD')}
      </Link>
      <div className="m-2" />
      <h5>{t('REGISTER_LABEL')}</h5>
      <Link className="btn-secondary link-button" to="/app/register">
        {t('REGISTER')}
      </Link>
    </div>
  )
}

export default Login
