import React from 'react'
import { Router } from '@reach/router'
import Layout from '../components/Layout'
import Login from '../components/Login'
import Register from '../components/Register'
import ForgotPassword from '../components/ForgotPassword'
import Dashboard from '../components/Dashboard'
import PrivateRoute from '../components/PrivateRoute'

const App = () => {
  return (
    <Layout languageRedirect={false}>
      <Router>
        <Login default path="app/login" />
        <Register path="app/register" />
        <ForgotPassword path="app/forgot-password" />
        <PrivateRoute path="app/dashboard" component={Dashboard} />
      </Router>
    </Layout>
  )
}

export default App
